<template>
  <page-setup>
    <v-container fluid>
      <v-row dense>
        <v-col>
          <div class="text-h5 text-color-main font-weight-black my-4">
            {{ title }}
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <experience-builder-component></experience-builder-component>
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
const ExperienceBuilderComponent = () =>
  import("../components/ExperienceBuilderComponent.vue");

export default {
  components: {
    ExperienceBuilderComponent
  },
  data: () => ({
    title: "Create Service"
  }),
  methods: {
    setTitle() {
      if (this.$route.params.id) {
        this.title = "Update Service";
        return;
      }

      this.title = "Create Service";
      return;
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>
